// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Function
// ----------------------------------------------------------------------------
/** resetShoppingCart */
const resetShoppingCart = (defaultState, previousState, payload) => {
  return Object.assign({}, previousState, {
    shoppingCartState: {
      objectsList: [],
      objectsCounts: {},
      totalCountOfObjects: 0,
      totalPriceOfObjects: 0,
      thatTotalPriceOfObjects: 0,
      thisTotalPriceOfObjects: 0,
    },
  })
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default resetShoppingCart
