// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import map from 'lodash/map'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Function
// ----------------------------------------------------------------------------
/** decreaseCountOfObjectInShoppingCart */
const decreaseCountOfObjectInShoppingCart = (
  defaultState,
  previousState,
  payload
) => {
  const thatTotalPriceOfObjects =
    previousState.shoppingCartState.totalPriceOfObjects
  const thisCountsOfObjects = previousState.shoppingCartState.objectsCounts
  let thisTotalCountOfObjects =
    previousState.shoppingCartState.totalCountOfObjects

  if (thisCountsOfObjects[payload] > 0) {
    thisCountsOfObjects[payload] -= 1
    thisTotalCountOfObjects =
      previousState.shoppingCartState.totalCountOfObjects - 1
  }

  const thisTotalPrice = []

  map(previousState.shoppingCartState.objectsList, object => {
    thisTotalPrice.push(object.price * thisCountsOfObjects[object.routeSlug])
  })

  const thisTotalPriceOfObjects = thisTotalPrice.reduce((a, b) => a + b, 0)

  return Object.assign({}, previousState, {
    shoppingCartState: {
      objectsList: previousState.shoppingCartState.objectsList,
      objectsCounts: thisCountsOfObjects,
      totalCountOfObjects: thisTotalCountOfObjects,
      totalPriceOfObjects: thisTotalPriceOfObjects,
      thatTotalPriceOfObjects,
      thisTotalPriceOfObjects,
    },
  })
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default decreaseCountOfObjectInShoppingCart
