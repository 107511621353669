// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-aura-story-page-template-index-jsx": () => import("./../../../src/components/aura-story-page-template/index.jsx" /* webpackChunkName: "component---src-components-aura-story-page-template-index-jsx" */),
  "component---src-components-aura-talk-page-template-index-jsx": () => import("./../../../src/components/aura-talk-page-template/index.jsx" /* webpackChunkName: "component---src-components-aura-talk-page-template-index-jsx" */),
  "component---src-components-blog-page-template-index-jsx": () => import("./../../../src/components/blog-page-template/index.jsx" /* webpackChunkName: "component---src-components-blog-page-template-index-jsx" */),
  "component---src-components-event-page-template-index-jsx": () => import("./../../../src/components/event-page-template/index.jsx" /* webpackChunkName: "component---src-components-event-page-template-index-jsx" */),
  "component---src-components-insight-page-template-index-jsx": () => import("./../../../src/components/insight-page-template/index.jsx" /* webpackChunkName: "component---src-components-insight-page-template-index-jsx" */),
  "component---src-components-news-page-template-index-jsx": () => import("./../../../src/components/news-page-template/index.jsx" /* webpackChunkName: "component---src-components-news-page-template-index-jsx" */),
  "component---src-components-tutorial-page-template-index-jsx": () => import("./../../../src/components/tutorial-page-template/index.jsx" /* webpackChunkName: "component---src-components-tutorial-page-template-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-apprentice-or-intern-jsx": () => import("./../../../src/pages/apprentice-or-intern.jsx" /* webpackChunkName: "component---src-pages-apprentice-or-intern-jsx" */),
  "component---src-pages-aura-stories-jsx": () => import("./../../../src/pages/aura-stories.jsx" /* webpackChunkName: "component---src-pages-aura-stories-jsx" */),
  "component---src-pages-aura-talks-jsx": () => import("./../../../src/pages/aura-talks.jsx" /* webpackChunkName: "component---src-pages-aura-talks-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-community-events-jsx": () => import("./../../../src/pages/community-events.jsx" /* webpackChunkName: "component---src-pages-community-events-jsx" */),
  "component---src-pages-community-feedback-jsx": () => import("./../../../src/pages/community-feedback.jsx" /* webpackChunkName: "component---src-pages-community-feedback-jsx" */),
  "component---src-pages-donate-jsx": () => import("./../../../src/pages/donate.jsx" /* webpackChunkName: "component---src-pages-donate-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-give-feedback-jsx": () => import("./../../../src/pages/give-feedback.jsx" /* webpackChunkName: "component---src-pages-give-feedback-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insights-jsx": () => import("./../../../src/pages/insights.jsx" /* webpackChunkName: "component---src-pages-insights-jsx" */),
  "component---src-pages-learn-jsx": () => import("./../../../src/pages/learn.jsx" /* webpackChunkName: "component---src-pages-learn-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-participate-jsx": () => import("./../../../src/pages/participate.jsx" /* webpackChunkName: "component---src-pages-participate-jsx" */),
  "component---src-pages-report-a-bug-jsx": () => import("./../../../src/pages/report-a-bug.jsx" /* webpackChunkName: "component---src-pages-report-a-bug-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-the-story-of-aura-jsx": () => import("./../../../src/pages/the-story-of-aura.jsx" /* webpackChunkName: "component---src-pages-the-story-of-aura-jsx" */),
  "component---src-pages-vision-jsx": () => import("./../../../src/pages/vision.jsx" /* webpackChunkName: "component---src-pages-vision-jsx" */)
}

