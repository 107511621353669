// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import filter from 'lodash/filter'
import map from 'lodash/map'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Function
// ----------------------------------------------------------------------------
/** pushObjectIntoShoppingCart */
const pushObjectIntoShoppingCart = (defaultState, previousState, payload) => {
  const thatTotalPriceOfObjects =
    previousState.shoppingCartState.totalPriceOfObjects
  const thatListOfObjects = previousState.shoppingCartState.objectsList
  const thisCountsOfObjects = previousState.shoppingCartState.objectsCounts
  const objectExists = filter(thatListOfObjects, {
    routeSlug: payload.routeSlug,
  })
  const objectExistsLen = Object.keys(objectExists).length
  const thisListOfObjects = thatListOfObjects
  const thisTotalCountOfObjects =
    previousState.shoppingCartState.totalCountOfObjects + 1
  const thisTotalPrice = []

  // It exists from before
  if (objectExistsLen === 1) {
    thisCountsOfObjects[payload.routeSlug] += 1
  } else {
    thisCountsOfObjects[payload.routeSlug] = 1
    thisListOfObjects.push(payload)
  }

  map(previousState.shoppingCartState.objectsList, object =>
    thisTotalPrice.push(object.price * thisCountsOfObjects[object.routeSlug])
  )

  const thisTotalPriceOfObjects = thisTotalPrice.reduce((a, b) => a + b, 0)

  return Object.assign({}, previousState, {
    shoppingCartState: {
      objectsList: thisListOfObjects,
      objectsCounts: thisCountsOfObjects,
      totalCountOfObjects: thisTotalCountOfObjects,
      totalPriceOfObjects: thisTotalPriceOfObjects,
      thatTotalPriceOfObjects,
      thisTotalPriceOfObjects,
    },
  })
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default pushObjectIntoShoppingCart
