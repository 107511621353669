// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import filter from 'lodash/filter'
import map from 'lodash/map'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Function
// ----------------------------------------------------------------------------
/** popObjectFromShoppingCart */
const popObjectFromShoppingCart = (defaultState, previousState, payload) => {
  const thatTotalPriceOfObjects =
    previousState.shoppingCartState.totalPriceOfObjects
  const thatListOfObjects = previousState.shoppingCartState.objectsList
  let thisCountsOfObjects = previousState.shoppingCartState.objectsCounts
  const objectExists = filter(thatListOfObjects, {
    routeSlug: payload,
  })
  const objectExistsLen = Object.keys(objectExists).length
  let thisListOfObjects = thatListOfObjects
  let thisTotalCountOfObjects =
    previousState.shoppingCartState.totalCountOfObjects
  const thisTotalPrice = []

  // It exists from before
  if (objectExistsLen === 1) {
    thisTotalCountOfObjects =
      thisTotalCountOfObjects - thisCountsOfObjects[payload]
    thisListOfObjects = filter(thatListOfObjects, o => o.routeSlug !== payload)
    thisCountsOfObjects[payload] = 0
  }

  map(previousState.shoppingCartState.objectsList, object =>
    thisTotalPrice.push(object.price * thisCountsOfObjects[object.routeSlug])
  )

  const thisTotalPriceOfObjects = thisTotalPrice.reduce((a, b) => a + b, 0)

  return Object.assign({}, previousState, {
    shoppingCartState: {
      objectsList: thisListOfObjects,
      objectsCounts: thisCountsOfObjects,
      totalCountOfObjects: thisTotalCountOfObjects,
      totalPriceOfObjects: thisTotalPriceOfObjects,
      thatTotalPriceOfObjects,
      thisTotalPriceOfObjects,
    },
  })
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default popObjectFromShoppingCart
